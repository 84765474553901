import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ChurchServiceApi from "../api/ChurchServiceApi";

export default function GetChurchServicesService({
  translate,
  church_id,
  day = null,
  time = null,
  active = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  const [churchServices, setChurchServices] = useState([]);

  useEffect(() => {
    const getChurchServices = async () => {
      try {
        const churchServices = await ChurchServiceApi({
          church_id: church_id,
          day: day,
          time: time,
          active: active,
          order_by: order_by,
          sort: sort,
          offset: offset,
          limit: limit,
          use_pagination: use_pagination,
        });

        const data = churchServices.data.data.rows;

        const days = [7];
        if (!days.includes(day)) {
          let addChurchService = null;

          addChurchService = {
            id: "100000000",
            name: "Special Service",
            time: "00:00:00",
            active: true,
          };

          data.push(addChurchService);
        }

        setChurchServices(data);
      } catch (error) {
        Swal.fire({
          title: "Church Services " + error.response.data.message,
          text: error,
          icon: "error",
          showCloseButton: true,
          confirmButtonText: translate("please_try_again"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=" +
            "Page : Home%0a" +
            "Content : Church Service%0a" +
            error +
            "' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };

    const fetchData = async () => {
      await getChurchServices();
    };

    fetchData();
  }, [
    church_id,
    day,
    time,
    active,
    order_by,
    sort,
    offset,
    limit,
    use_pagination,
    translate,
  ]);

  return churchServices;
}
