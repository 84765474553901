import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import {
  FaCalendar,
  FaClock,
  FaHome,
  FaList,
  FaPlus,
  FaTrash,
} from "react-icons/fa";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

import Swal from "sweetalert2";
import ScheduleApi from "../api/ScheduleApi";

export default function SchedulePage({
  translate,
  setIsLoading,
  account,
  totalPersonMinistry,
}) {
  const [schedules, setSchedules] = useState([]);

  const personId = account.mygms_id;

  useEffect(() => {
    setIsLoading(true);
    CheckCookiesUtil();

    const fetchData = async () => {
      try {
        const response = await ScheduleApi({
          church_id: null,
          church_service_id: null,
          ministry_id: null,
          division_id: null,
          datetime: null,
          person_id: personId,
          deleted_at: null,
          order_by: null,
          sort: null,
          offset: null,
          limit: null,
          use_pagination: null,
        });
        setSchedules(response.data.data.rows);
      } catch (error) {
        Swal.fire({
          title: "Get Schedules",
          text: error,
          icon: "error",
          confirmButtonText: translate("ok"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=```" +
            "Page : Schedule%0a" +
            "Content : Get Data%0a" +
            error +
            "```' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };
    fetchData();
    setIsLoading(false);
  }, [translate, setIsLoading, personId]);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const titlePage = translate("schedule");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaList },
  ];

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?" + id,
      text: "Once deleted, you will not be able to recover this item!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // Perform the delete action id
        // Call your delete API or perform any necessary operations
        // ...
        // Show a success message after successful deletion
        Swal.fire("Deleted!", "Your item has been deleted.", "success");
      }
    });
  };

  return (
    <>
      <h2>
        <FaList className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <Row>
        <Col xs={7}>
          <Link to="/schedule/add">
            <Button color="primary" className="my-2 w-100">
              <FaPlus className="me-2" />
              {translate("add")} {translate("schedule")}
            </Button>
          </Link>
        </Col>
        <Col xs={5}>
          <Link to="/schedule/trash">
            <Button color="danger" className="my-2 w-100">
              <FaTrash className="me-2" />
              {translate("trash")}
            </Button>
          </Link>
        </Col>
      </Row>

      {schedules.map((schedule, key) => (
        <Card key={key} className="mt-4">
          <CardBody>
            <CardTitle tag="h5">{schedule.church.name}</CardTitle>
            <CardSubtitle>{schedule.church_service.name}</CardSubtitle>
            <hr />
            <Row>
              <Col xs={6}>
                <FaCalendar className="me-2" />
                {moment(schedule.datetime).format("D MMMM YYYY")}
              </Col>
              <Col xs={6} className="text-end">
                <FaClock className="me-2" />
                {moment(schedule.datetime).format("HH:mm")} WIB
              </Col>
            </Row>
            <hr />
            <Row>
              <Col xs={4}>
                <Link to={`/schedule/${schedule.id}`}>
                  <Button
                    color="info"
                    size="sm"
                    className="my-2 w-100 text-white"
                  >
                    <FaTrash className="me-2" />
                    {translate("detail")}
                  </Button>
                </Link>
              </Col>
              <Col xs={4}>
                <Link to={`/schedule/${schedule.id}`}>
                  <Button color="success" size="sm" className="my-2 w-100">
                    <FaTrash className="me-2" />
                    {translate("edit")}
                  </Button>
                </Link>
              </Col>
              <Col xs={4}>
                <Button
                  color="danger"
                  size="sm"
                  className="my-2 w-100"
                  onClick={() => handleDelete(schedule.id)}
                >
                  <FaTrash className="me-2" />
                  {translate("delete")}
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      ))}
    </>
  );
}
