import axios from "axios";
import { account_url } from "../utils/ConstantsUtil";

export default async function AccountApi(token) {
  return await axios.get(account_url + "/v2/me", {
    headers: {
      Authorization: "Bearer " + token,
    },
  });
}
