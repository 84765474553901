import Cookies from "universal-cookie";
import RedirectAuthUtil from "../utils/RedirectAuthUtil";

const cookies = new Cookies();

export default function CheckCookiesUtil() {
  if (!cookies.get("code")) {
    return RedirectAuthUtil();
  }
}
