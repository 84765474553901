import React, { useEffect, useState } from "react";
import moment from "moment";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import { FaHome, FaCalendarCheck, FaTable } from "react-icons/fa";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import CheckSuperUser from "../services/CheckSuperUser";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import GetChurchesService from "../services/GetChurchesService";
import GetChurchServicesService from "../services/GetChurchServicesService";
import GetMinistriesService from "../services/GetMinistriesService";
import GetDivisionsService from "../services/GetDivisionsService";
import GetAbsentsService from "../services/GetAbsentsService";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";

export default function DataAbsentPage({
  translate,
  account,
  personMinistry,
  totalPersonMinistry,
}) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });
  CheckSuperUser({ account });

  const titlePage = translate("data") + " " + translate("absent");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaTable },
  ];

  const [churchId, setChurchId] = useState(account.church.church_id);
  const [churchName, setChurchName] = useState(account.church.name);
  const [ministryId, setMinistryId] = useState(personMinistry.ministry.id);
  const [ministryName, setMinistryName] = useState(
    personMinistry.ministry.name
  );
  const [divisionId, setDivisionId] = useState(personMinistry.division.id);
  const [divisionName, setDivisionName] = useState(
    personMinistry.division.name
  );
  const [churchServiceId, setChurchServiceId] = useState(null);
  const [churchServiceName, setChurchServiceName] = useState(null);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  let churches = GetChurchesService({
    translate,
    name: null,
    statuses: null,
    is_deleted: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  churches = churches.map(function (church) {
    return { value: church.couchdb_id, label: church.name };
  });

  let churchServices = GetChurchServicesService({
    translate,
    church_id: churchId,
    day: null,
    time: null,
    active: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  churchServices = churchServices.map(function (churchService) {
    return {
      value: churchService.id,
      label:
        churchService.time.substr(0, churchService.time.length - 3) +
        " - " +
        churchService.name,
    };
  });

  let ministries = GetMinistriesService({
    translate,
    name: null,
    deleted_at: null,
    order_by: "name",
    sort: "asc",
    offset: null,
    limit: null,
    use_pagination: null,
  });

  ministries = ministries.map(function (ministry) {
    return { value: ministry.id, label: ministry.name };
  });

  let divisions = GetDivisionsService({
    translate,
    ministry_id: ministryId,
    name: null,
    deleted_at: null,
    order_by: "name",
    sort: "asc",
    offset: null,
    limit: null,
    use_pagination: null,
  });

  divisions = divisions.map(function (division) {
    return { value: division.id, label: division.name };
  });

  const [resetKeyChurchService, setResetKeyChurchService] = useState(0);

  function onChangeChurch(value) {
    setChurchName(value.label);
    setChurchId(value.value);
    setChurchServiceId(null);
    setChurchServiceName(null);
    setResetKeyChurchService((prevKey) => prevKey + 1);
  }

  function onChangeChurchService(value) {
    setChurchServiceName(value.label);
    setChurchServiceId(value.value);
  }

  const [resetKeyDivision, setResetKeyDivision] = useState(0);

  function onChangeMinistry(value) {
    setMinistryName(value.label);
    setMinistryId(value.value);
    setDivisionId(null);
    setDivisionName(null);
    setResetKeyDivision((prevKey) => prevKey + 1);
  }

  function onChangeDivision(value) {
    setDivisionName(value.label);
    setDivisionId(value.value);
  }

  function onChangeDate(value) {
    setDate(value.target.value);
  }

  const absents = GetAbsentsService({
    translate,
    person_id: null,
    church_id: churchId,
    church_service_id: churchServiceId,
    ministry_id: ministryId,
    division_id: divisionId,
    date: date,
    deleted_at: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  return (
    <>
      <h2>
        <FaCalendarCheck className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <Card className="mb-4">
        <CardBody>
          <CardTitle tag="h5">
            {translate("search")} {translate("absent")}{" "}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
            {translate("total_data")} : {absents.length}
          </CardSubtitle>

          <FormGroup>
            <Label for="church_id">{translate("church")}</Label>
            <Select
              id="church_id"
              options={churches}
              placeholder={translate("select") + " " + translate("church")}
              onChange={(value) => onChangeChurch(value)}
              defaultValue={{
                label: churchName ?? account.church.name,
                value: churchId ?? account.church.church_id,
              }}
              value={{
                label: churchName ?? account.church.name,
                value: churchId ?? account.church.church_id,
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="ministry_id">{translate("ministry")}</Label>
            <Select
              id="ministry_id"
              options={ministries}
              placeholder={translate("all") + " " + translate("ministry")}
              onChange={(value) => onChangeMinistry(value)}
              defaultValue={{
                label:
                  ministryName ??
                  translate("all") + " " + translate("ministry"),
                value: ministryId ?? null,
              }}
              value={{
                label:
                  ministryName ??
                  translate("all") + " " + translate("ministry"),
                value: ministryId ?? null,
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label for="division_id">{translate("division")}</Label>
            <Select
              id="division_id"
              options={divisions}
              placeholder={translate("all") + " " + translate("division")}
              onChange={(value) => onChangeDivision(value)}
              defaultValue={{
                label:
                  divisionName ??
                  translate("all") + " " + translate("division"),
                value: divisionId ?? null,
              }}
              value={{
                label:
                  divisionName ??
                  translate("all") + " " + translate("division"),
                value: divisionId ?? null,
              }}
              key={resetKeyDivision}
            />
          </FormGroup>

          <FormGroup>
            <Label for="church_service_id">{translate("church_service")}</Label>
            <Select
              id="church_service_id"
              options={churchServices}
              placeholder={translate("all") + " " + translate("church_service")}
              onChange={(value) => onChangeChurchService(value)}
              defaultValue={{
                label: churchServiceName ?? translate("all_church_service"),
                value: churchServiceId ?? null,
              }}
              value={{
                label: churchServiceName ?? translate("all_church_service"),
                value: churchServiceId ?? null,
              }}
              key={resetKeyChurchService}
            />
          </FormGroup>

          <FormGroup>
            <Label for="date">{translate("date")}</Label>
            <Input
              type="date"
              id="date"
              name="date"
              onChange={onChangeDate}
              defaultValue={moment().format("YYYY-MM-DD")}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {absents.map((absent, key) => (
        <div key={absent.id}>
          <Table responsive striped bordered>
            <thead color="dark">
              <tr className="bg-dark text-white">
                <th>
                  <span>{key + 1}. </span>
                  <span>{absent.people?.name}</span>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{absent.church.name}</td>
              </tr>
              <tr>
                <td>{absent.ministry?.name}</td>
              </tr>
              <tr>
                <td>{absent.division?.name}</td>
              </tr>
              <tr>
                <td>{moment(absent.created_at).format("D MMMM YYYY")}</td>
              </tr>
              <tr>
                <td>{moment(absent.created_at).format("HH:mm")} WIB</td>
              </tr>
            </tbody>
          </Table>
        </div>
      ))}
    </>
  );
}
