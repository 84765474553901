import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import MinistryApi from "../api/MinistryApi";

export default function GetMinistriesService({
  translate,
  name = null,
  deleted_at = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  const [ministries, setMinistries] = useState([]);

  useEffect(() => {
    const getMinistries = async () => {
      try {
        const ministries = await MinistryApi({
          name: name,
          deleted_at: deleted_at,
          order_by: order_by,
          sort: sort,
          offset: offset,
          limit: limit,
          use_pagination: use_pagination,
        });
        setMinistries(ministries.data.data.rows);
      } catch (error) {
        Swal.fire({
          title: "Ministries " + error.response.data.message,
          text: error,
          icon: "error",
          showCloseButton: true,
          confirmButtonText: translate("please_try_again"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=" +
            "Page : Data Absent%0a" +
            "Content : Get Data Ministries%0a" +
            error +
            "' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };

    const fetchData = async () => {
      await getMinistries();
    };

    fetchData();
  }, [
    translate,
    name,
    deleted_at,
    order_by,
    sort,
    offset,
    limit,
    use_pagination,
  ]);

  return ministries;
}
