import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FaArrowLeft, FaHome, FaExclamationCircle } from "react-icons/fa";
import Error from "../assets/images/error.png";
import { title } from "../utils/ConstantsUtil";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function ErrorPage({ translate }) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  const titlePage = translate("error");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaExclamationCircle },
  ];

  return (
    <>
      <h2>
        <FaExclamationCircle className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={Error}
          alt={titlePage + " - " + title}
        />

        <h2 className="fw-bold">{translate("error")}</h2>
        <h4>{translate("no_data_available")}</h4>
        <p>
          {translate("sorry_the_service_is_not_available")}
          <br />
          {translate("please_check_back_later")}
        </p>

        <Link to="/">
          <Button color="dark" className="my-2">
            <FaArrowLeft className="me-2" />
            {translate("back_to_home")}
          </Button>
        </Link>
      </div>
    </>
  );
}
