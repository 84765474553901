import React, { useEffect } from "react";
import moment from "moment";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap";
import { FaHome, FaUserCircle } from "react-icons/fa";
import { title } from "../utils/ConstantsUtil";
import { handleUserImageError } from "../utils/ImageNotFoundUtil";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import { experience } from "../utils/DurationUtil";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function ProfilePage({
  translate,
  account,
  totalPersonMinistry,
  personMinistries,
}) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const titlePage = translate("profile");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaUserCircle },
  ];

  return (
    <>
      <h2>
        <FaUserCircle className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <Card className="mb-4">
        <CardBody className="text-center">
          <div className="mb-3">
            <img
              className="w-50"
              draggable="false"
              src={account.photo_url}
              alt={translate("user") + " - " + title}
              onError={handleUserImageError}
            />
          </div>
          <CardTitle tag="h5" className="fw-bold">
            {account.name}
          </CardTitle>
          <CardSubtitle>{account.nij}</CardSubtitle>
          <CardSubtitle>{account.church.name}</CardSubtitle>
          <CardSubtitle>{translate(account.gender.toLowerCase())}</CardSubtitle>
        </CardBody>
      </Card>

      <UncontrolledAccordion defaultOpen="1">
        {personMinistries.map((personMinistry, key) => (
          <AccordionItem key={key}>
            <AccordionHeader targetId={String(key + 1)}>
              {personMinistry.division.name}
            </AccordionHeader>
            <AccordionBody accordionId={String(key + 1)}>
              <div>
                <span className="fw-bold">{translate("ministry")} : </span>
                <span>{personMinistry.ministry.name}</span>
              </div>
              <div>
                <span className="fw-bold">{translate("division")} : </span>
                <span>{personMinistry.division.name}</span>
              </div>
              <hr />
              <div>
                <span className="fw-bold">{translate("status")} : </span>
                <span>
                  {personMinistry.status === 2
                    ? translate("active")
                    : translate("non_active")}
                </span>
              </div>
              <div>
                <span className="fw-bold">{translate("position")} : </span>
                <span className="text-capitalize">
                  {personMinistry.position.toLowerCase()}
                </span>
              </div>
              <hr />
              <div>
                <span className="fw-bold">{translate("experience")} : </span>
                <span>{experience(personMinistry.created_at, translate)}</span>
              </div>
              <div>
                <span className="fw-bold">{translate("since")} : </span>
                <span>
                  {moment(personMinistry.created_at).format("DD MMMM YYYY")}
                </span>
              </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </UncontrolledAccordion>
    </>
  );
}
