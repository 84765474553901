import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FaArrowLeft, FaHome, FaTools } from "react-icons/fa";
import Maintenance from "../assets/images/maintenance.png";
import { title } from "../utils/ConstantsUtil";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function MaintenancePage({ translate }) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  const titlePage = translate("maintenance");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaTools },
  ];

  return (
    <>
      <h2>
        <FaTools className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <div className="text-center">
        <img
          className="w-100"
          draggable="false"
          src={Maintenance}
          alt={translate("maintenance") + " - " + title}
        />

        <h2 className="fw-bold">{translate("maintenance")}</h2>
        <h4>{translate("this_page_is_under_construction")}</h4>
        <p>
          {translate("sorry_the_service_is_not_available")}
          <br />
          {translate("please_check_back_later")}
        </p>

        <Link to="/">
          <Button color="dark" className="my-2">
            <FaArrowLeft className="me-2" />
            {translate("back_to_home")}
          </Button>
        </Link>
      </div>
    </>
  );
}
