import React from "react";
import Logo from "../assets/images/logo.png";
import { title } from "../utils/ConstantsUtil";
import NavbarComponent from "./NavbarComponent";
// import BreadcrumbComponent from "./BreadcrumbComponent";

export default function HeaderComponent({
  translate,
  account,
  totalPersonMinistry,
}) {
  return (
    <>
      {totalPersonMinistry === 0 ? (
        <div className="text-center pt-4">
          <img
            draggable="false"
            className="me-2"
            height="50"
            src={Logo}
            alt={"Logo - " + title}
          />

          <div className="d-inline-block align-middle">
            <h1 className="text-uppercase fw-bold text-dark mb-0">
              {process.env.REACT_APP_NAME}
            </h1>
          </div>
        </div>
      ) : (
        <>
          <NavbarComponent translate={translate} account={account} />
          {/* <BreadcrumbComponent /> */}
        </>
      )}
    </>
  );
}
