import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { super_user } from "../utils/ConstantsUtil";

export default function CheckSuperUser({ account }) {
  const navigate = useNavigate();

  useEffect(() => {
    if (account.mygms_id !== super_user) {
      return navigate("/page-not-found");
    }
  }, [account, navigate]);
}
