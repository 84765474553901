import React from "react";
import { Routes, Route } from "react-router-dom";

import HomePage from "../pages/HomePage";
import AbsentPage from "../pages/AbsentPage";
import SchedulePage from "../pages/SchedulePage";
import ScheduleAddPage from "../pages/ScheduleAddPage";
import ProfilePage from "../pages/ProfilePage";
import ChurchPage from "../pages/ChurchPage";
import KnowledgeBasePage from "../pages/KnowledgeBasePage";

import NonActivePage from "../pages/NonActivePage";
import ErrorPage from "../pages/ErrorPage";
import Error404Page from "../pages/Error404Page";
import LocalStoragePage from "../pages/LocalStoragePage";
import MaintenancePage from "../pages/MaintenancePage";

import LogoutService from "../services/LogoutService";
import DataAbsentPage from "../pages/DataAbsentPage";

const router = ({
  translate,
  setIsLoading,
  account,
  totalPersonMinistry,
  personMinistry,
  personMinistries,
}) => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <HomePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
            personMinistry={personMinistry}
            personMinistries={personMinistries}
          />
        }
      />
      <Route
        exact
        path="/absent"
        element={
          <AbsentPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/schedule"
        element={
          <MaintenancePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/profile"
        element={
          <ProfilePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
            personMinistries={personMinistries}
          />
        }
      />
      <Route
        exact
        path="/church"
        element={
          <ChurchPage
            translate={translate}
            setIsLoading={setIsLoading}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/knowledge-base"
        element={
          <MaintenancePage
            translate={translate}
            setIsLoading={setIsLoading}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/non-active"
        element={
          <NonActivePage
            translate={translate}
            setIsLoading={setIsLoading}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/local-storage"
        element={
          <LocalStoragePage translate={translate} setIsLoading={setIsLoading} />
        }
      />
      <Route
        exact
        path="/logout"
        element={
          <LogoutService translate={translate} setIsLoading={setIsLoading} />
        }
      />
      <Route
        exact
        path="/error"
        element={
          <ErrorPage translate={translate} setIsLoading={setIsLoading} />
        }
      />
      <Route
        exact
        path="/data-absent"
        element={
          <DataAbsentPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            personMinistry={personMinistry}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="*"
        element={
          <Error404Page translate={translate} setIsLoading={setIsLoading} />
        }
      />
      <Route
        exact
        path="/new-schedule"
        element={
          <SchedulePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/schedule/add"
        element={
          <ScheduleAddPage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
      <Route
        exact
        path="/new-knowledge-base"
        element={
          <KnowledgeBasePage
            translate={translate}
            setIsLoading={setIsLoading}
            account={account}
            totalPersonMinistry={totalPersonMinistry}
          />
        }
      />
    </Routes>
  );
};

export default router;
