import React, { useEffect, useState } from "react";
import "../styles/ProgressBarStyle.css";

const ProgressBar = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleLoad = () => {
      setProgress(100); // Set progress to 100% when the page is loaded

      // Reset progress to 0% after a delay of 500ms
      setTimeout(() => {
        setProgress(0);
      }, 500);
    };

    // console.log(progress);

    const progressBarElement = document.querySelector(".progress-bar");
    progressBarElement.classList.add("reset");

    window.addEventListener("load", handleLoad);

    return () => {
      window.removeEventListener("load", handleLoad);
    };
  }, []);

  return <div className="progress-bar" style={{ width: `${progress}%` }}></div>;
};

export default ProgressBar;
