import axios from "axios";
import {
  api_url,
  header_content_type,
  header_accept,
  header_key,
} from "../utils/ConstantsUtil";

export default async function DivisionApi({
  ministry_id = null,
  name = null,
  deleted_at = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  return await axios.get(api_url + "/division", {
    params: {
      ministry_id: ministry_id,
      name: name,
      deleted_at: deleted_at,
      order_by: order_by,
      sort: sort,
      offset: offset,
      limit: limit,
      use_pagination: use_pagination,
    },
    headers: {
      "Content-Type": header_content_type,
      Accept: header_accept,
      key: header_key,
    },
  });
}
