import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";
import { Button, Card, CardHeader, ListGroup, ListGroupItem } from "reactstrap";
import moment from "moment";
import Cookies from "universal-cookie";

export default function LocalStorageComponent() {
  const [t] = useTranslation("language");
  const jwt = JSON.parse(localStorage.getItem("jwt"));
  const account = JSON.parse(localStorage.getItem("account"));
  const event = JSON.parse(localStorage.getItem("event"));

  console.log({ ...localStorage });

  return (
    <>
      <Card color="primary" outline>
        <CardHeader className="bg-primary text-white text-center">
          {t("local_storage")}
        </CardHeader>

        <ListGroup flush>
          <ListGroupItem>
            <div className="fw-bold">{t("jwt")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(jwt, null, 4)}</code>
              </pre>
            </div>
            <hr />
            <div>{jwt.person_id}</div>
            <div>{jwt.application}</div>
            <div>{jwt.scopes.join(", ")}</div>
            <div>{moment.unix(jwt.iat).format("LLLL")}</div>
            <div>{moment.unix(jwt.exp).format("LLLL")}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("cookies")}</div>
            <div>
              <pre>
                <code>
                  {JSON.stringify(new Cookies().get("account"), null, 4) ?? "-"}
                </code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("code")}</div>
            <div>{localStorage.getItem("code") ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("account")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(account, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("event")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(event, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("volunteer")}</div>
            <div>{localStorage.getItem("volunteer") ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("step")}</div>
            <div>{localStorage.getItem("step") ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("i18nextLng")}</div>
            <div>{localStorage.getItem("i18nextLng") ?? "-"}</div>
          </ListGroupItem>

          <ListGroupItem>
            <div className="fw-bold">{t("local_storage")}</div>
            <div>
              <pre>
                <code>{JSON.stringify(localStorage, null, 4)}</code>
              </pre>
            </div>
          </ListGroupItem>
        </ListGroup>
      </Card>

      <Link to="/">
        <Button color="primary" className="w-100 mt-4">
          {t("back_to_home")}
          <FaArrowRight className="ms-2" />
        </Button>
      </Link>
    </>
  );
}
