import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Link } from "react-router-dom";

export const Breadcrumbs = ({ items }) => {
  return (
    <Breadcrumb className="bg-light rounded px-3 pt-3 pb-1 mb-4">
      {items.map((item, index) => (
        <BreadcrumbItem key={index} active={index === items.length - 1}>
          {item.link ? (
            <Link to={item.link} className="text-decoration-none">
              {item.icon && <item.icon className="me-2" />}
              {item.name}
            </Link>
          ) : (
            <>
              {item.icon && <item.icon className="me-2" />}
              {item.name}
            </>
          )}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};
