import axios from "axios";
import {
  api_url,
  header_content_type,
  header_accept,
  header_key,
} from "../utils/ConstantsUtil";

export default async function ScheduleApi({
  church_id = null,
  church_service_id = null,
  ministry_id = null,
  division_id = null,
  datetime = null,
  person_id = null,
  deleted_at = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  return await axios.get(api_url + "/schedule", {
    params: {
      church_id,
      church_service_id,
      ministry_id,
      division_id,
      datetime,
      person_id,
      deleted_at,
      order_by: order_by,
      sort: sort,
      offset: offset,
      limit: limit,
      use_pagination: use_pagination,
    },
    headers: {
      "Content-Type": header_content_type,
      Accept: header_accept,
      key: header_key,
    },
  });
}
