import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";
import RedirectAuthUtil from "../utils/RedirectAuthUtil";
import AccountApi from "../api/AccountApi";
import PersonMinistriesApi from "../api/PersonMinistriesApi";
import PersonMinistryApi from "../api/PersonMinistryApi";

const cookies = new Cookies();

export const getCode = async () => {
  const code = new URLSearchParams(window.location.search).get("code");

  if (code) {
    const jwt = jwt_decode(code);
    let date = new Date(0);
    date.setUTCSeconds(jwt.exp);

    cookies.set("code", code, {
      expires: date,
    });

    window.history.replaceState(null, null, window.location.pathname);

    return code;
  }

  if (!cookies.get("code")) {
    return RedirectAuthUtil();
  }

  return cookies.get("code");
};

export const getDate = async (code) => {
  const jwt = jwt_decode(code);
  let date = new Date(0);
  date.setUTCSeconds(jwt.exp);

  return date;
};

export const getAccount = async (code) => {
  const account = await AccountApi(code);

  return account.data.result;
};

export const getTotalPersonMinistry = async (person_id) => {
  const personMinistry = await PersonMinistriesApi({
    person_id: person_id,
    // person_id: "0054089a-ab29-46b7-8ae5-b1d19c606387", // TESTING
    church_id: null,
    ministry_id: null,
    division_id: null,
    end_year: null,
    status: 2,
    is_active: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  return personMinistry.data.data.count;
};

export const getPersonMinistry = async (person_id) => {
  const personMinistry = await PersonMinistryApi({
    person_id: person_id,
    // person_id: "0054089a-ab29-46b7-8ae5-b1d19c606387", // TESTING
    church_id: null,
    ministry_id: null,
    division_id: null,
    end_year: null,
    status: 2,
    is_active: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  return personMinistry.data.data;
};
