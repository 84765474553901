import React, { useState, useEffect } from "react";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import { title } from "../utils/ConstantsUtil";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import ImageNotAvailable from "../assets/images/image-not-available.png";
import { FaSearch, FaHome, FaChurch } from "react-icons/fa";

import { getGmsChurches } from "../services/GetGmsChurchesService";
import GmsChurchApi from "../api/GmsChurchApi";
import Swal from "sweetalert2";

import axios from "axios";
import { gms_churches } from "../utils/ConstantsUtil";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function ChurchPage({
  translate,
  setIsLoading,
  totalPersonMinistry,
}) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const titlePage = translate("church");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaChurch },
  ];

  const [search, setSearch] = useState("");
  const [churches, setChurches] = useState([]);

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get(gms_churches, {
          params: {
            show_all: false,
          },
        });
        setChurches(response.data.result.rows);
      } catch (error) {
        console.log("Show Swal");
        Swal.fire({
          title: "Get GMS Churches",
          text: error,
          icon: "error",
          confirmButtonText: translate("ok"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=```" +
            "Page : GMS Church%0a" +
            "Content : Get Data%0a" +
            error +
            "```' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };
    setIsLoading(false);

    fetchData();
  }, [translate, setIsLoading]);

  return (
    <>
      <h2>
        <FaChurch className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <InputGroup className="mb-3">
        <InputGroupText>
          <FaSearch />
        </InputGroupText>
        <Input
          type="search"
          id="search"
          name="search"
          placeholder={translate("search") + " " + translate("church")}
          maxLength="100"
          onChange={onChangeSearch}
        />
      </InputGroup>

      <Row>
        {churches
          .filter(
            (church) =>
              church.name.toLowerCase().includes(search.toLowerCase()) ||
              church.status.toLowerCase().includes(search.toLowerCase()) ||
              church.regional.toLowerCase().includes(search.toLowerCase())
          )
          .map((item) => (
            <Col className="mb-4" sm="12" lg="12" xl="12" key={item.church_id}>
              <Card>
                <img
                  draggable="false"
                  className="img-fluid w-100"
                  src={item.place_image ?? ImageNotAvailable}
                  alt={titlePage + " - " + item.name + " - " + title}
                />
                <CardBody>
                  <CardTitle tag="h5">{item.name}</CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6">
                    {item.regional}
                  </CardSubtitle>
                  <CardText>
                    {translate("status")} : {item.status.toUpperCase()}
                    <br />
                    {translate("regional")} : {item.regional.toUpperCase()}
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          ))}
      </Row>
    </>
  );
}
