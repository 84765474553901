import axios from "axios";
import {
  api_url,
  header_content_type,
  header_accept,
  header_key,
} from "../utils/ConstantsUtil";

export default async function PersonMinistryApi({
  person_id,
  church_id = null,
  ministry_id = null,
  division_id = null,
  end_year = null,
  status = null,
  is_active = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  return axios.get(api_url + "/person-ministry/" + person_id, {
    params: {
      person_id: person_id,
      church_id: church_id,
      ministry_id: ministry_id,
      division_id: division_id,
      end_year: end_year,
      status: status,
      is_active: is_active,
      order_by: order_by,
      sort: sort,
      offset: offset,
      limit: limit,
      use_pagination: use_pagination,
    },
    headers: {
      "Content-Type": header_content_type,
      Accept: header_accept,
      key: header_key,
    },
  });
}
