import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { Button, FormGroup, Input, Label } from "reactstrap";
import { FaArrowLeft, FaHome, FaList, FaPlus } from "react-icons/fa";
import Maintenance from "../assets/images/maintenance.png";
import { title } from "../utils/ConstantsUtil";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function ScheduleAddPage({ translate, totalPersonMinistry }) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const titlePage = translate("schedule");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, link: "/new-schedule", icon: FaList },
    { name: translate("add"), icon: FaPlus },
  ];

  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));

  function onChangeDate(value) {
    setDate(value.target.value);
  }

  return (
    <>
      <h2>
        <FaList className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <FormGroup>
        <Label for="date">{translate("date")}</Label>
        <Input
          type="date"
          id="date"
          name="date"
          onChange={onChangeDate}
          defaultValue={moment().format("YYYY-MM-DD")}
        />
      </FormGroup>
    </>
  );
}
