import axios from "axios";
import {
  api_url,
  header_content_type,
  header_accept,
  header_key,
} from "../utils/ConstantsUtil";

export default async function ChurchServiceApi({
  church_id,
  name = null,
  day = null,
  time = null,
  active = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  return await axios.get(api_url + "/church-service", {
    params: {
      church_id: church_id,
      name: name,
      day: day,
      time: time,
      active: active,
      order_by: order_by,
      sort: sort,
      offset: offset,
      limit: limit,
      use_pagination: use_pagination,
    },
    headers: {
      "Content-Type": header_content_type,
      Accept: header_accept,
      key: header_key,
    },
  });
}
