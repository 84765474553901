import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import { FaArrowLeft, FaHome, FaExclamationCircle } from "react-icons/fa";
import NotFound from "../assets/images/404-not-found.png";
import { title } from "../utils/ConstantsUtil";
import { Breadcrumbs } from "../components/BreadcrumbsComponent";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function Error404Page({ translate }) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  const titlePage = translate("page_not_found");

  const breadcrumbItems = [
    { name: translate("home"), link: "/", icon: FaHome },
    { name: titlePage, icon: FaExclamationCircle },
  ];

  return (
    <>
      <h2>
        <FaExclamationCircle className="me-2" />
        {titlePage}
      </h2>

      <Breadcrumbs items={breadcrumbItems} />

      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={NotFound}
          alt={titlePage + " - " + title}
        />

        <h2 className="fw-bold">{translate("error")}</h2>
        <h4>
          {translate("404")} - {translate("not_found")}
        </h4>
        <p>{translate("the_page_you_requested_could_not_be_found")}</p>

        <Link to="/">
          <Button color="dark" className="my-2">
            <FaArrowLeft className="me-2" />
            {translate("back_to_home")}
          </Button>
        </Link>
      </div>
    </>
  );
}
