import React, { useEffect } from "react";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import { title } from "../utils/ConstantsUtil";
import NonActive from "../assets/images/non-active.png";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";

export default function NonActivePage({ translate, totalPersonMinistry }) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const titlePage = translate("non_active");

  return (
    <>
      <div className="text-center">
        <img
          draggable="false"
          className="w-100"
          src={NonActive}
          alt={titlePage + " - " + title}
        />
        <h2 className="fw-bold">{titlePage}</h2>
        <h4>{translate("you_are_not_active_as_a_volunteer")}</h4>
        <p>
          {translate("sorry_at_this_time_you_cannot_access_gms_volunteer_apps")}
        </p>
      </div>
    </>
  );
}
