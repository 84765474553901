import axios from "axios";
import {
  api_url,
  header_content_type,
  header_accept,
  header_key,
} from "../utils/ConstantsUtil";

export default async function CheckInApi({
  person_id,
  church_id,
  church_service_id,
  ministry_id,
  division_id,
}) {
  const data = {
    person_id: person_id,
    church_id: church_id,
    church_service_id: church_service_id,
    ministry_id: ministry_id,
    division_id: division_id,
  };

  return await axios.post(api_url + "/absent", data, {
    headers: {
      "Content-Type": header_content_type,
      Accept: header_accept,
      key: header_key,
    },
  });
}
