import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import AbsentApi from "../api/AbsentApi";

export default function GetAbsentsService({
  translate,
  person_id = null,
  church_id = null,
  church_service_id = null,
  ministry_id = null,
  division_id = null,
  date = null,
  deleted_at = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  const [absents, setAbsents] = useState([]);

  useEffect(() => {
    const getAbsents = async () => {
      try {
        const absents = await AbsentApi({
          person_id: person_id,
          church_id: church_id,
          church_service_id: church_service_id,
          ministry_id: ministry_id,
          division_id: division_id,
          date: date,
          deleted_at: deleted_at,
          order_by: order_by,
          sort: sort,
          offset: offset,
          limit: limit,
          use_pagination: use_pagination,
        });
        setAbsents(absents.data.data.rows);
      } catch (error) {
        Swal.fire({
          title: "Absents " + error.response.data.message,
          text: error,
          icon: "error",
          confirmButtonText: translate("please_try_again"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=```" +
            "Page : Absent%0a" +
            "Content : Get Data%0a" +
            error +
            "```' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };

    const fetchData = async () => {
      await getAbsents();
    };

    fetchData();
  }, [
    person_id,
    church_id,
    church_service_id,
    ministry_id,
    division_id,
    date,
    deleted_at,
    order_by,
    sort,
    offset,
    limit,
    use_pagination,
    translate,
  ]);

  return absents;
}
