import {
  auth_url,
  response_type,
  client_id,
  redirect_uri,
} from "./ConstantsUtil";

export default function RedirectAuthUtil() {
  window.location =
    auth_url +
    "/oauth/authorize?response_type=" +
    response_type +
    "&client_id=" +
    client_id +
    "&redirect_uri=" +
    redirect_uri;
}
