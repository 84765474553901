import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import DivisionApi from "../api/DivisionApi";

export default function GetDivisionsService({
  translate,
  ministry_id = null,
  name = null,
  deleted_at = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  const [divisions, setDivisions] = useState([]);

  useEffect(() => {
    const getDivisions = async () => {
      try {
        const divisions = await DivisionApi({
          ministry_id: ministry_id,
          name: name,
          deleted_at: deleted_at,
          order_by: order_by,
          sort: sort,
          offset: offset,
          limit: limit,
          use_pagination: use_pagination,
        });
        setDivisions(divisions.data.data.rows);
      } catch (error) {
        Swal.fire({
          title: "Divisions " + error.response.data.message,
          text: error,
          icon: "error",
          showCloseButton: true,
          confirmButtonText: translate("please_try_again"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=" +
            "Page : Data Absent%0a" +
            "Content : Get Data Divisions%0a" +
            error +
            "' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };

    const fetchData = async () => {
      await getDivisions();
    };

    fetchData();
  }, [
    ministry_id,
    name,
    deleted_at,
    order_by,
    sort,
    offset,
    limit,
    use_pagination,
    translate,
  ]);

  return divisions;
}
