import Swal from "sweetalert2";
import CheckInApi from "../api/CheckInApi";

export default function CheckInService({
  person_id,
  church_id,
  church_service_id,
  ministry_id,
  division_id,
  churchName,
  successTitle,
  successText,
  successButton,
  duplicateTitle,
  duplicateText,
  duplicateButton,
  errorButton,
  errorFooter,
}) {
  CheckInApi({
    person_id: person_id,
    church_id: church_id,
    church_service_id: church_service_id,
    ministry_id: ministry_id,
    division_id: division_id,
  })
    .then((response) => {
      return Swal.fire({
        title: successTitle,
        text: successText,
        icon: "success",
        showCloseButton: true,
        confirmButtonText: successButton,
        confirmButtonColor: "#198754",
        footer: churchName,
      });
    })
    .catch((error) => {
      if (error.response.data.code === 409) {
        return Swal.fire({
          title: duplicateTitle,
          text: duplicateText,
          icon: "warning",
          showCloseButton: true,
          confirmButtonText: duplicateButton,
          confirmButtonColor: "#FFC107",
          footer: churchName,
        });
      }
      return Swal.fire({
        title: "Check In " + error.response.data.message,
        text: error,
        icon: "error",
        showCloseButton: true,
        confirmButtonText: errorButton,
        confirmButtonColor: "#DC3545",
        footer:
          "<a href='https://wa.me/6282298089363?text=" +
          "Page : Home%0a" +
          "Content : Check In%0a" +
          error +
          "' target='_blank'>" +
          errorFooter +
          "</a>",
      });
    });
}
