import React from "react";
import LogoSquare from "../assets/images/logo-white.png";
import { title } from "../utils/ConstantsUtil";
// import { FaUndo } from "react-icons/fa";
// import { Button } from "reactstrap";

export default function LoaderComponent({ translate }) {
  return (
    <div className="vh-100 bg-dark text-white">
      <div className="row h-100">
        <div className="col-10 col-md-6 col-lg-5 col-xl-8 mx-auto text-center align-self-center">
          <div>
            <img
              draggable="false"
              alt={"Logo White - " + title}
              src={LogoSquare}
              width="100"
            />
          </div>
          <h1 className="mt-2 fw-bold">VOLUNTEER</h1>
          <div className="spinner-border my-4" role="status"></div>
          <h3>{translate("welcome_home")}</h3>
          <h6>{translate("now_loading")}</h6>
          {/* <div className="small mt-5 mb-3 text-warning">
            {translate("if_the_page_does_not_reload_properly_please_refresh")}
          </div>
          <Button color="light">
            <FaUndo /> Refresh
          </Button> */}
        </div>
      </div>
    </div>
  );
}
