import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useTranslation } from "react-i18next";

import Route from "./routes/route";

import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import LoaderComponent from "./components/LoaderComponent";

import {
  getCode,
  getDate,
  getAccount,
  getTotalPersonMinistry,
  getPersonMinistry,
} from "./services/CookiesSetService";

import ProgressBarComponent from "./components/ProgressBarComponent";

export default function App() {
  require(localStorage.getItem("i18nextLng") === "en"
    ? "moment/locale/en-gb"
    : "moment/locale/id");

  const [translate] = useTranslation("language");

  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(null);
  const [totalPersonMinistry, setTotalPersonMinistry] = useState(0);
  const [personMinistry, setPersonMinistry] = useState(null);
  const [personMinistries, setPersonMinistries] = useState([]);

  const fetchData = async () => {
    const code = await getCode();
    if (code) {
      const account = await getAccount(code);
      const totalPersonMinistry = await getTotalPersonMinistry(
        account.mygms_id
      );
      const personMinistry = await getPersonMinistry(account.mygms_id);
      setAccount(account);
      setTotalPersonMinistry(Number(totalPersonMinistry));
      setPersonMinistry(personMinistry[0]);
      setPersonMinistries(personMinistry);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isLoading) {
    return <LoaderComponent translate={translate} />;
  }

  return (
    <Router>
      <ProgressBarComponent />
      <HeaderComponent
        translate={translate}
        account={account}
        totalPersonMinistry={totalPersonMinistry}
      />
      <Container className="py-5 mt-4">
        <Row className="justify-content-center">
          <Col xs="12" sm="10" md="8" lg="6" xl="4">
            <Route
              translate={translate}
              setIsLoading={setIsLoading}
              account={account}
              totalPersonMinistry={totalPersonMinistry}
              personMinistry={personMinistry}
              personMinistries={personMinistries}
            />
          </Col>
        </Row>
      </Container>
      <FooterComponent translate={translate} />
    </Router>
  );
}
