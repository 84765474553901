import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { I18nextProvider } from "react-i18next";
import XHR from "i18next-http-backend";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./i18n/en.json";
import id from "./i18n/id.json";

const language =
  localStorage.getItem("i18nextLng") ?? process.env.REACT_APP_LANGUAGE;

const resources = {
  en: { language: en },
  id: { language: id },
};

const options = {
  order: [
    "querystring",
    "cookie",
    "localStorage",
    "sessionStorage",
    "navigator",
    "htmlTag",
    "path",
    "subdomain",
  ],
  caches: ["localStorage"],
  lookupQuerystring: "lng",
};

i18next
  .use(XHR)
  .use(LanguageDetector)
  .init({
    detection: options,
    resources,
    ns: ["language"],
    defaultNS: "language",
    interpolation: { escapeValue: false },
    lng: language,
    fallbackLng: language,
    supportedLngs: ["en", "id"],
    debug: false,
  });

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

reportWebVitals();
