export const api_url = process.env.REACT_APP_API_URL;
export const auth_url = process.env.REACT_APP_AUTH_URL;
export const account_url = process.env.REACT_APP_ACCOUNT_URL;
export const file_url = process.env.REACT_APP_FILE_URL;
export const header_content_type = process.env.REACT_APP_HEADER_CONTENT_TYPE;
export const header_accept = process.env.REACT_APP_HEADER_ACCEPT;
export const header_key = process.env.REACT_APP_HEADER_KEY;
export const response_type = process.env.REACT_APP_RESPONSE_TYPE;
export const client_id = process.env.REACT_APP_CLIENT_ID;
export const redirect_uri = process.env.REACT_APP_REDIRECT_URL;
export const gms_churches = process.env.REACT_APP_GMS_CHURCHES;
export const scope = process.env.REACT_APP_SCOPE;
export const state = process.env.REACT_APP_STATE;
export const type = process.env.REACT_APP_TYPE;
export const token = process.env.REACT_APP_TOKEN;
export const name = process.env.REACT_APP_NAME;
export const title = process.env.REACT_APP_TITLE;
export const max_image_size = process.env.REACT_APP_MAX_IMAGE_SIZE;
export const max_image_size_text = process.env.REACT_APP_MAX_IMAGE_SIZE_TEXT;
export const image_type = process.env.REACT_APP_IMAGE_TYPE;
export const image_type_text = process.env.REACT_APP_IMAGE_TYPE_TEXT;

export const super_user = "a360265027fc7ba3f08938443bd03b62";
