import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { Button } from "reactstrap";

export default function ChangeLanguageComponent() {
  const [translate, i18n] = useTranslation("language");
  const language =
    localStorage.getItem("i18nextLng") ?? process.env.REACT_APP_LANGUAGE;

  const [updateLanguage, setUpdateLanguage] = useState(language);

  const changeLanguage = (data) => {
    localStorage.setItem("language", data);
    require(data === "id" ? "moment/locale/id" : "moment/locale/en-gb");
    setUpdateLanguage(data);
  };

  return (
    <div className="text-center mb-4">
      <h6>{translate("change_language")}</h6>
      <Button
        color="link"
        className="text-decoration-none"
        onClick={() => [changeLanguage("en"), i18n.changeLanguage("en")]}
      >
        <Flag code="us" height="16" />
        <span
          className={
            updateLanguage === "en"
              ? "ms-2 text-dark fw-bold"
              : "ms-2 text-dark"
          }
        >
          English
        </span>
      </Button>
      <Button
        color="link"
        className="text-decoration-none"
        onClick={() => [changeLanguage("id"), i18n.changeLanguage("id")]}
      >
        <Flag code="id" height="16" />
        <span
          className={
            updateLanguage === "id"
              ? "ms-2 text-dark fw-bold"
              : "ms-2 text-dark"
          }
        >
          Indonesia
        </span>
      </Button>
    </div>
  );
}
