import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { title, name, super_user } from "../utils/ConstantsUtil";
import LogoWhite from "../assets/images/logo-white.png";
import {
  FaBook,
  FaCalendarCheck,
  FaChurch,
  FaHome,
  FaList,
  FaSignOutAlt,
  FaTable,
  FaUserCircle,
} from "react-icons/fa";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from "reactstrap";

export default function NavbarComponent({ translate, account }) {
  const location = useLocation().pathname;
  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);
  const toggleNavbarLogo = () => setCollapsed(true);

  return (
    <Navbar color="dark" dark fixed="top">
      <Link to="/" onClick={toggleNavbarLogo} className="text-decoration-none">
        <NavbarBrand href="/" className="me-auto mb-2">
          <img
            draggable="false"
            width={80}
            src={LogoWhite}
            alt={"Logo White - " + title}
          />
          <span className="ms-2">{name}</span>
        </NavbarBrand>
      </Link>

      <NavbarToggler onClick={toggleNavbar} />

      <Collapse isOpen={!collapsed} navbar>
        <Nav navbar>
          <NavItem className="mt-2">
            <Link
              className="text-decoration-none"
              to="/"
              onClick={toggleNavbar}
            >
              <NavLink active={location === "/" ? true : false}>
                <FaHome className="me-2" />
                {translate("home")}
              </NavLink>
            </Link>

            <Link
              className="text-decoration-none"
              to="/absent"
              onClick={toggleNavbar}
            >
              <NavLink active={location === "/absent" ? true : false}>
                <FaCalendarCheck className="me-2" />
                {translate("absent")}
              </NavLink>
            </Link>

            <Link
              className="text-decoration-none"
              to="/schedule"
              onClick={toggleNavbar}
            >
              <NavLink active={location === "/schedule" ? true : false}>
                <FaList className="me-2" />
                {translate("schedule")}
              </NavLink>
            </Link>

            <Link
              className="text-decoration-none"
              to="/profile"
              onClick={toggleNavbar}
            >
              <NavLink active={location === "/profile" ? true : false}>
                <FaUserCircle className="me-2" />
                {translate("profile")}
              </NavLink>
            </Link>

            <Link className="text-decoration-none" to="/logout">
              <NavLink>
                <FaSignOutAlt className="me-2" />
                {translate("logout")}
              </NavLink>
            </Link>

            <NavLink>
              <hr className="my-0 py-0" />
            </NavLink>

            <Link
              className="text-decoration-none"
              to="/church"
              onClick={toggleNavbar}
            >
              <NavLink active={location === "/church" ? true : false}>
                <FaChurch className="me-2" />
                {translate("church")}
              </NavLink>
            </Link>

            <Link
              className="text-decoration-none"
              to="/knowledge-base"
              onClick={toggleNavbar}
            >
              <NavLink active={location === "/knowledge-base" ? true : false}>
                <FaBook className="me-2" />
                {translate("knowledge_base")}
              </NavLink>
            </Link>

            {account.mygms_id === super_user ? (
              <>
                <NavLink>
                  <hr className="my-0 py-0" />
                </NavLink>

                <Link
                  className="text-decoration-none"
                  to="/data-absent"
                  onClick={toggleNavbar}
                >
                  <NavLink active={location === "/data-absent" ? true : false}>
                    <FaTable className="me-2" />
                    {translate("data")} {translate("absent")}
                  </NavLink>
                </Link>

                <Link
                  className="text-decoration-none"
                  to="/new-schedule"
                  onClick={toggleNavbar}
                >
                  <NavLink active={location === "/new-schedule" ? true : false}>
                    <FaList className="me-2" />
                    {translate("schedule")}
                  </NavLink>
                </Link>

                <Link
                  className="text-decoration-none"
                  to="/new-knowledge-base"
                  onClick={toggleNavbar}
                >
                  <NavLink
                    active={location === "/new-knowledge-base" ? true : false}
                  >
                    <FaBook className="me-2" />
                    {translate("knowledge_base")}
                  </NavLink>
                </Link>
              </>
            ) : null}
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
}
