import React, { useState, useEffect } from "react";
import CheckStatusVolunteerService from "../services/CheckStatusVolunteerService";
import { FormGroup, Label, Form, Button, Alert, Input } from "reactstrap";
import moment from "moment";
import Select from "react-select";
import CheckCookiesUtil from "../utils/CheckCookiesUtil";
import GetChurchesService from "../services/GetChurchesService";
import GetChurchServicesService from "../services/GetChurchServicesService";
import CheckInService from "../services/CheckInService";

export default function HomePage({
  translate,
  account,
  totalPersonMinistry,
  personMinistry,
  personMinistries,
}) {
  useEffect(() => {
    CheckCookiesUtil();
  }, []);

  CheckStatusVolunteerService({ totalPersonMinistry });

  const [churchId, setChurchId] = useState(account.church.church_id);
  const [churchName, setChurchName] = useState(account.church.name);
  const [churchServiceId, setChurchServiceId] = useState(null);
  const [churchServiceName, setChurchServiceName] = useState(null);
  const [ministryId, setMinistryId] = useState(null);
  const [ministryName, setMinistryName] = useState(null);
  const [divisionId, setDivisionId] = useState(null);
  const [divisionName, setDivisionName] = useState(null);

  useEffect(() => {
    setChurchId(account.church.church_id);
    setChurchName(account.church.name);
    setMinistryId(personMinistry?.ministry.id);
    setMinistryName(personMinistry?.ministry.name);
    setDivisionId(personMinistry?.division?.id);
    setDivisionName(personMinistry?.division?.name);
  }, [
    account.church.church_id,
    account.church.name,
    personMinistry?.ministry.id,
    personMinistry?.ministry.name,
    personMinistry?.division?.id,
    personMinistry?.division?.name,
  ]);

  let churches = GetChurchesService({
    name: null,
    statuses: null,
    is_deleted: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  churches = churches.map(function (church) {
    return { value: church.couchdb_id, label: church.name };
  });

  const day = new Date().getDay();

  let churchServices = GetChurchServicesService({
    church_id: churchId,
    day: day === 0 ? 7 : day,
    time: null,
    active: null,
    order_by: null,
    sort: null,
    offset: null,
    limit: null,
    use_pagination: null,
  });

  churchServices = churchServices.map(function (churchService) {
    return {
      value: churchService.id,
      label:
        churchService.id === "100000000"
          ? churchService.name
          : churchService.time.substr(0, churchService.time.length - 3) +
            " - " +
            churchService.name,
    };
  });

  let ministries = personMinistries;

  if (personMinistry) {
    ministries = ministries.map(function (ministry) {
      return { value: ministry?.ministry.id, label: ministry?.ministry.name };
    });
  }

  let divisions = personMinistries;

  if (personMinistry) {
    divisions = divisions
      .filter((division) => division?.ministry.id === ministryId)
      .map(function (division) {
        return {
          value: division?.division?.id,
          label: division?.division?.name,
        };
      });
  }

  const [resetKeyChurchService, setResetKeyChurchService] = useState(0);

  function onChangeChurch(value) {
    setChurchName(value.label);
    setChurchId(value.value);
    setChurchServiceId(null);
    setChurchServiceName(null);
    setResetKeyChurchService((prevKey) => prevKey + 1);
  }

  function onChangeChurchService(value) {
    setChurchServiceName(value.label);
    setChurchServiceId(value.value);
  }

  const [resetKeyDivision, setResetKeyDivision] = useState(0);

  function onChangeMinistry(value) {
    setMinistryName(value.label);
    setMinistryId(value.value);
    setDivisionId(null);
    setDivisionName(null);
    setResetKeyDivision((prevKey) => prevKey + 1);
  }

  function onChangeDivision(value) {
    setDivisionName(value.label);
    setDivisionId(value.value);
  }

  function submitForm(e) {
    e.preventDefault();

    CheckInService({
      person_id: account.mygms_id,
      church_id: churchId,
      church_service_id: churchServiceId,
      ministry_id: ministryId,
      division_id: divisionId,
      churchName: account.church.name,
      successTitle: translate("check_in_success"),
      successText: translate("thank_you_for_checking_in"),
      successButton: translate("ok"),
      duplicateTitle: translate("you_already_check_in"),
      duplicateText: translate(
        "you_cannot_check_in_on_the_same_day_and_at_the_same_service_hours"
      ),
      duplicateButton: translate("ok"),
      errorButton: translate("please_try_again"),
      errorFooter: translate("send_error_to_developer"),
    });
  }

  return (
    <>
      <Alert color="dark">
        <span className="text-capitalize">{translate("welcome_home")},</span>
        <span className="text-capitalize ms-1">
          {account.name.toLowerCase()}
        </span>
      </Alert>

      <Form onSubmit={submitForm}>
        <FormGroup>
          <Label for="church_id" className="fw-bold">
            {translate("church")}
          </Label>
          <Select
            id="church_id"
            options={churches}
            placeholder={translate("select") + " " + translate("church")}
            onChange={(value) => onChangeChurch(value)}
            defaultValue={{
              label: churchName ?? account.church.name,
              value: churchId ?? account.church.church_id,
            }}
            value={{
              label: churchName ?? account.church.name,
              value: churchId ?? account.church.church_id,
            }}
            required
          />
        </FormGroup>

        <FormGroup>
          <Label for="ministry_id" className="fw-bold">
            {translate("ministry")}
          </Label>
          {totalPersonMinistry > 1 ? (
            <Select
              id="ministry_id"
              options={ministries}
              placeholder={translate("select") + " " + translate("ministry")}
              onChange={(value) => onChangeMinistry(value)}
              defaultValue={{
                label:
                  ministryName ??
                  translate("select") + " " + translate("ministry"),
                value: ministryId ?? null,
              }}
              value={{
                label:
                  ministryName ??
                  translate("select") + " " + translate("ministry"),
                value: ministryId ?? null,
              }}
              required
            />
          ) : (
            <Input
              id="ministry_id"
              defaultValue={ministryName}
              plaintext
              readOnly
            />
          )}
        </FormGroup>

        <FormGroup>
          <Label for="division_id" className="fw-bold">
            {translate("division")}
          </Label>
          {totalPersonMinistry > 1 ? (
            <Select
              id="division_id"
              options={divisions}
              placeholder={translate("select") + " " + translate("division")}
              onChange={(value) => onChangeDivision(value)}
              defaultValue={{
                label:
                  divisionName ??
                  translate("select") + " " + translate("division"),
                value: divisionId ?? null,
              }}
              value={{
                label:
                  divisionName ??
                  translate("select") + " " + translate("division"),
                value: divisionId ?? null,
              }}
              key={resetKeyDivision}
              required
            />
          ) : (
            <Input
              id="division_id"
              defaultValue={divisionName}
              plaintext
              readOnly
            />
          )}
        </FormGroup>

        <FormGroup>
          <Label for="date" className="fw-bold">
            {translate("day")} & {translate("date")}
          </Label>
          <Input
            id="date"
            defaultValue={moment().format("dddd, DD MMMM YYYY")}
            plaintext
            readOnly
          />
        </FormGroup>

        <FormGroup>
          <Label for="church_service_id" className="fw-bold">
            {translate("church_service")}
          </Label>
          <Select
            id="church_service_id"
            options={churchServices}
            placeholder={
              translate("select") + " " + translate("church_service")
            }
            onChange={(value) => onChangeChurchService(value)}
            defaultValue={{
              label: churchServiceName ?? translate("select_church_service"),
              value: churchServiceId ?? null,
            }}
            value={{
              label: churchServiceName ?? translate("select_church_service"),
              value: churchServiceId ?? null,
            }}
            key={resetKeyChurchService}
            required
          />
        </FormGroup>

        <Button
          type="submit"
          color="dark"
          className="w-100 mt-2"
          disabled={
            !churchId || !churchServiceId || !ministryId || !divisionId
              ? true
              : false
          }
        >
          {translate("check_in")} {translate("now")}
        </Button>
      </Form>
    </>
  );
}
