import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import ChurchApi from "../api/ChurchApi";

export default function GetChurchesService({
  translate,
  name = null,
  statuses = null,
  is_deleted = null,
  order_by = null,
  sort = null,
  offset = null,
  limit = null,
  use_pagination = null,
}) {
  const [churches, setChurches] = useState([]);

  useEffect(() => {
    const getChurches = async () => {
      try {
        const churches = await ChurchApi({
          name: null,
          statuses: ["local", "satelit"],
          is_deleted: false,
          order_by: "name",
          sort: "asc",
          offset: null,
          limit: null,
          use_pagination: null,
        });
        setChurches(churches.data.data.rows);
      } catch (error) {
        Swal.fire({
          title: "Churches " + error.response.data.message,
          text: error,
          icon: "error",
          showCloseButton: true,
          confirmButtonText: translate("please_try_again"),
          confirmButtonColor: "#DC3545",
          footer:
            "<a href='https://wa.me/6282298089363?text=" +
            "Page : Home%0a" +
            "Content : Church%0a" +
            error +
            "' target='_blank'>" +
            translate("send_error_to_developer") +
            "</a>",
        });
      }
    };

    const fetchData = async () => {
      await getChurches();
    };

    fetchData();
  }, [
    name,
    statuses,
    is_deleted,
    order_by,
    sort,
    offset,
    limit,
    use_pagination,
    translate,
  ]);

  return churches;
}
