import Cookies from "universal-cookie";
import RedirectAuthUtil from "../utils/RedirectAuthUtil";

export default function LogoutService() {
  localStorage.clear();

  new Cookies().remove("code");

  RedirectAuthUtil();
}
