import moment from "moment";

export const duration = (targetDate) => {
  const targetMoment = moment(targetDate, "YYYY-MM-DD");
  const duration = moment.duration(targetMoment.diff(moment()));

  const years = duration.years();
  const months = duration.months();
  const days = duration.days();

  return { years, months, days };
};

export const days = (targetDate) => {
  const targetMoment = moment(targetDate, "YYYY-MM-DD");
  const duration = moment.duration(targetMoment.diff(moment()));

  const days = duration.days();

  return days;
};

export const months = (targetDate) => {
  const targetMoment = moment(targetDate, "YYYY-MM-DD");
  const duration = moment.duration(targetMoment.diff(moment()));

  const months = duration.months();

  return months;
};

export const years = (targetDate) => {
  const targetMoment = moment(targetDate, "YYYY-MM-DD");
  const duration = moment.duration(targetMoment.diff(moment()));

  const years = duration.years();

  return years;
};

export const experience = (targetDate, translate) => {
  const targetMoment = moment(targetDate, "YYYY-MM-DD");
  const duration = moment.duration(targetMoment.diff(moment()));

  const days = Math.abs(duration.days());
  const months = Math.abs(duration.months());
  const years = Math.abs(duration.years());

  if (years !== 0) {
    return (
      years +
      " " +
      translate("years") +
      " " +
      months +
      " " +
      translate("months")
    );
  } else if (months !== 0) {
    return (
      months + " " + translate("months") + " " + days + " " + translate("days")
    );
  } else if (days !== 0) {
    return days + " " + translate("days");
  } else {
    return "Your First Day";
  }
};
